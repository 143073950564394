import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
// import modalIMG from 'images/thank-you-icon';

const PdpaModal = (props) => {
  return (
    <Modal {...props} size='lg' aria-labelledby='pdpa-modal' centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='mb-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col xs={12} md={10}>
              <p>
                By providing your business contact information in this form, we
                may periodically send you electronic direct mailers consisting
                of retail and technology related information as well as new
                services relating to DDS. Any e-mail sent by DDS provides you
                with the option to unsubscribe from the DDS mailing list.
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PdpaModal;
