import React, { useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PdpaModal from './PdpaModal';

import FbIcon from '../packs/images/facebook.svg';
import LnIcon from '../packs/images/linkedin-ico.svg';
import { removeClass } from 'utilities/helpers';
const Footer = () => {
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute('content');

  const [subscribe, setSubscribe] = useState('');
  const [submitted, setSubmitted] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const refSubscribeInput = useRef(null);

  const handleChangeSubscription = (event) => {
    event.preventDefault();
    removeClass('show-error', refSubscribeInput);

    setSubscribe(event.target.value);
  };
  const submitSubscribe = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      refSubscribeInput.current.className = `${refSubscribeInput.current.className} show-error`;

      return;
    }
    const response = await fetch('/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ email: subscribe }),
    }).then((res) => {
      if (res.ok) {
        setSubscribe('');
        setSubmitted(true);
      }
    });
  };
  return (
    <Container fluid className='footer'>
      <Container>
        <Row className='footer_content justify-content-between'>
          <Col className='col-md-auto px-1' sm={12}>
            <div className='footer_content_logo mx-auto mx-md-0'></div>
            <div className=' text-md-left text-center footer_text footer-address'>
              The Plaza, 7500A Beach Road <br />
              #11-308, Singapore 199591
            </div>
          </Col>

          <Col
            className='text-md-left text-center d-flex flex-md-column justify-content-center justify-content-md-start col-md-auto px-1'
            sm={12}
          >
            <div className='footer-title d-none d-md-block'>Company</div>
            <div className='my-auto my-md-0'>
              <a
                className='footer_text'
                target='_blank'
                rel='noopener'
                href='https://www.retaildds.com/privacy/'
              >
                Privacy Policy
              </a>
            </div>
            <div className='footer-pipe mx-5 d-block d-md-none'>|</div>
            <div className='my-auto my-md-0'>
              <a
                className='footer_text'
                target='_blank'
                rel='noopener'
                href='https://www.retaildds.com/terms/'
              >
                Terms &amp; Conditions
              </a>
            </div>
          </Col>
          <hr className='footer-break d-block d-md-none' />

          <Col className='text-md-left text-center col-md-auto px-1' sm={12}>
            <div className='footer-title d-none d-md-block'>
              Stay in the know
            </div>
            <div className='footer_text footer-subscribe-text'>
              Subscribe to our retail feed
            </div>
            {!submitted ? (
              <form
                noValidate
                onSubmit={submitSubscribe}
                className='footer-form'
              >
                <div className='footer-input input-group mt-2 mx-auto mx-md-0'>
                  <input
                    ref={refSubscribeInput}
                    id='email'
                    name='email'
                    type='email'
                    value={subscribe}
                    className='form-control'
                    placeholder='Your email'
                    onChange={handleChangeSubscription}
                  ></input>
                  <div className='error-message'></div>
                  <div className='input-group-append'>
                    <button
                      className='footer-btn btn'
                      type='submit'
                      disabled={subscribe === ''}
                      aria-label='Subscribe'
                    >
                      <i uk-icon='icon: chevron-right;' />
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div className='justify-content-center footer-thank-you mt-2'>
                <div className='footer_text footer-thank-you-text' xs='auto'>
                  <p>Thanks for signing up to our feed!</p>
                </div>
              </div>
            )}

            <div className='pdpa-policy'>
              <a
                className='footer_text'
                onClick={() => {
                  setModalShow(true);
                }}
              >
                View PDPA Policy
              </a>
            </div>
          </Col>

          <Col
            className='text-md-left text-center justify-content-center justify-content-md-start col-md-auto px-1'
            sm={12}
          >
            <div className='footer-title d-none d-md-block'>Quick Connect</div>
            <div>
              <a
                href='mailto:transform@retaildds.com'
                className='footer_text footer-email-address my-auto my-md-0'
              >
                transform@retaildds.com
              </a>
              <div>
                <a
                  target='_blank'
                  rel='noopener'
                  href='https://www.facebook.com/Retail-DDS-Asia-Pte-Ltd-106235284429061'
                >
                  <img className='fb-icon' src={FbIcon} alt='fbIcon' />
                </a>
                <a
                  target='_blank'
                  rel='noopener'
                  rel='noopener'
                  href='https://linkedin.com/company/dds-data-driven-solution'
                >
                  <img className='ln-icon' src={LnIcon} alt='lnIcon' />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <PdpaModal
        dialogClassName='success-modal'
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Container>
  );
};
export default Footer;
