import React, { useState, useEffect, useRef } from 'react';
import Aurora01 from 'images/aurora01';
import Aurora02 from 'images/aurora02';
import Aurora03 from 'images/aurora03';

const SliderAurora = () => {
  const [activeControl, setActiveControl] = useState(true);

  useEffect(() => {
    const handleSlideIn = (event) => {
      const eTClass = event.target.className;
      event.target.className = `${event.target.className} zoom`;
      if (
        eTClass.includes('item-1') ||
        eTClass.includes('item-2') ||
        eTClass.includes('item-3')
      ) {
        setActiveControl(true);
      }
      if (
        eTClass.includes('item-4') ||
        eTClass.includes('item-5') ||
        eTClass.includes('item-6')
      ) {
        setActiveControl(false);
      }
    };
    const handleSlideOut = (event) => {
      event.target.className = event.target.className.replace(/zoom/g, '');
    };
    // code to run on component mount
    window.addEventListener('itemshow', handleSlideIn);
    window.addEventListener('itemhide', handleSlideOut);

    return () => {
      window.removeEventListener('itemhide', handleSlideOut);
      window.removeEventListener('itemshow', handleSlideIn);
    };
  }, []);

  return (
    <div className='aurora-slider'>
      <div className='d-none d-md-block'>
        <div uk-slider='center: true' className='aurora-slider-dk'>
          <div
            className='uk-position-relative uk-visible-toggle uk-light mb-5 mt-4'
            tabIndex='-1'
          >
            <ul className='uk-slider-items'>
              <li className=' uk-width-1-3 item-1 slider-item s-item1 zoom'>
                <img src={Aurora01} alt='aurora' />
              </li>
              <li className=' uk-width-1-3 item-2 slider-item s-item3'>
                <img src={Aurora03} alt='aurora' />
              </li>
              <li className=' uk-width-1-3 item-3 slider-item s-item2'>
                <img src={Aurora02} alt='aurora' />
              </li>
              <li className=' uk-width-1-3 item-4 slider-item s-item1'>
                <img src={Aurora01} alt='aurora' />
              </li>
              <li className=' uk-width-1-3 item-5 slider-item s-item3'>
                <img src={Aurora03} alt='aurora' />
              </li>
              <li className=' uk-width-1-3 item-6 slider-item s-item2'>
                <img src={Aurora02} alt='aurora' />
              </li>
            </ul>
          </div>

          <ul
            className={` uk-dotnav uk-flex-center aurora-slider-control ${
              activeControl ? ' active-control' : ''
            }`}
          >
            <li uk-slider-item='0'>
              <a href=''></a>
            </li>
            <li uk-slider-item='1'>
              <a href=''></a>
            </li>
            <li uk-slider-item='2'>
              <a href=''></a>
            </li>
          </ul>
          <ul
            className={`uk-dotnav uk-flex-center position-absolute ${
              activeControl ? '' : ' active-control'
            }`}
            style={{
              transform: 'translate(-27px, -25px)',
              left: '50%',
            }}
          >
            <li uk-slider-item='3'>
              <a href=''></a>
            </li>
            <li uk-slider-item='4'>
              <a href=''></a>
            </li>
            <li uk-slider-item='5'>
              <a href=''></a>
            </li>
          </ul>
        </div>
      </div>
      <div className='d-block d-md-none'>
        <div uk-slider='center: true' className=''>
          <div
            className='uk-position-relative uk-visible-toggle uk-light mb-5'
            tabIndex='-1'
          >
            <ul className='uk-slider-items'>
              <li className='uk-width slider-item'>
                <img src={Aurora01} alt='aurora' />
              </li>
              <li className='uk-width slider-item'>
                <img src={Aurora02} alt='aurora' />
              </li>
              <li className='uk-width slider-item'>
                <img src={Aurora03} alt='aurora' />
              </li>
            </ul>
          </div>

          <ul className=' uk-dotnav uk-flex-center pt-3'>
            <li uk-slider-item='0'>
              <a href=''></a>
            </li>
            <li uk-slider-item='1'>
              <a href=''></a>
            </li>
            <li uk-slider-item='2'>
              <a href=''></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SliderAurora;
