import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { DESCRIPTIONS } from 'utilities/constants';
import Description from './Description';

const itemsDescription = DESCRIPTIONS.map((element, idx) => {
  return <Description key={idx} data={element} idx={idx} />;
});

const SecondSection = () => {
  return (
    <section
      id='steps-to-get-started'
      className='section-2'>
      <Container>
        <Row className='justify-content-center'>
          <Col
            className='content-section-title'
            xs={10}
            xl={12}
            lg={12}
            uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
          >
            <h1>3 Easy steps to bridge the gap</h1>
          </Col>
        </Row>
        {itemsDescription}
      </Container>
    </section>
  );
};
export default SecondSection;
