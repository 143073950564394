import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BGVideo from 'videos/bg_video.mp4';
import BGPoster from 'images/bg_poster.jpg';
import sectionImg from 'images/section-1-mobile';
import { removeClass } from 'utilities/helpers';

const FirstSection = ({ handleScroll }) => {
  const refVideo = useRef(null);
  const refQuestion = useRef(null);
  const refTitle1 = useRef(null);
  const refTitle2 = useRef(null);
  const refParagraph = useRef(null);
  const refButton = useRef(null);
  const [state, setState] = useState(null);
  const questClass =
    'd-none d-sm-flex justify-content-around content-section-question';
  const videoTimePause = 1.59;

  const handleMouseOver = (event) => {
    let currentTime = refVideo.current.currentTime;
    if (!state) {
      refVideo.current.play();
      startAnimation();
      setState('initial');
    } else if (state === 'final') {
    }
  };

  const handleMouseOut = (event) => {
    let currentTime = refVideo.current.currentTime;
    if (state === 'final') {
      refVideo.current.play();
      endAnimation();
    } else {
      if (currentTime <= videoTimePause) {
        refVideo.current.play();
        setState('initial');
      }
    }
  };
  const handleTimeUpdate = (event) => {
    const currentTime = event.target.currentTime;
    if (currentTime >= videoTimePause && state === 'initial') {
      event.target.pause();
      setState('final');
    }
  };
  const handlePlay = (event) => {
    if(videoIcons.current[idx].currentTime === 0){
        refVideo.current.play();
    }
  };

  const handlePlaying = (target) => {
    const currentTime = target.currentTime;
    const timer = setTimeout(() => {
      handlePlaying(target);
    }, 10);
    if (currentTime >= videoTimePause && state === 'initial') {
      target.pause();
      setState('final');
      clearTimeout(timer);
    }
    if (state === 'final') {
      clearTimeout(timer);
    }
  };

  const startAnimation = () => {
    removeClass('visible', refQuestion);
    removeClass('item-out-1', refTitle1);
    removeClass('item-out-1b', refTitle2);
    removeClass('item-out-2', refParagraph);
    removeClass('item-out-3', refButton);
    refQuestion.current.className = `${questClass} hidden`;
  };
  const endAnimation = () => {
    removeClass('hidden', refQuestion);
    refQuestion.current.className = `${questClass} visible`;
    refTitle1.current.className = `${refTitle1.current.className} item-out-1`;
    refTitle2.current.className = `${refTitle2.current.className} item-out-1b`;
    refParagraph.current.className = `${refParagraph.current.className} item-out-2`;
    refButton.current.className = `${refButton.current.className} item-out-3`;
  };
  useEffect(() => {
    document.addEventListener('touchstart', handlePlay);
  });

  return (
    <>
      <section
        id='banner'
        className='section-1'
      >
        <Container className='position-absolute section-1-container contianer-lg'>
          <Row className='header header justify-content-center justify-content-sm-between mt-sm-0'>
            <Col xs={6} sm={6} md={4} className=''>
              <div className='header-logo-img' />
            </Col>
            
          </Row>

          <div
            className='d-block d-md-none'
            uk-scrollspy='target: > div; cls:uk-animation-slide-bottom-small; delay: 250'
          >
            <Row className='d-flex d-sm-none justify-content-around'>
              <Col xs={10} sm={6} md={4} className='content-section-title px-0'>
            <h1>Bridge the gap between your <br/> shoppers and the cashier</h1>

              </Col>
            </Row>
            <Row className='d-flex d-sm-none justify-content-around'>
              <Col xs={12} sm={8} md={6} className='content-section-text'>
                <p className=''>
                  Start using retail analytics to measure lost potentials hidden in your store
                  
                </p>
              </Col>
            </Row>
            <Row className='d-flex d-sm-none content-section-action'>
              <Col>
                <Button onClick={handleScroll} className='content-section-btn'>
                  Get a Demo
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
        <Container fluid className='position-absolute section-1-container'>
          <div className='d-none d-sm-block'>
            <div ref={refQuestion} className={questClass}>
            </div>
            <div
              ref={refTitle1}
              className='container-fluid item-out-1 item-in-1'
            >
              <Col xs={12} className='content-section-title mx-auto'>
                <h1>Bridge the gap between your</h1>
                
              </Col>
            </div>
            <div
              ref={refTitle2}
              className='container-fluid item-out-1b item-in-1b'
            >
              <Col xs={12} className='content-section-title mx-auto'>
                <h1>shoppers and the cashier</h1>

              </Col>
            </div>
            <div
              ref={refParagraph}
              className='container-fluid item-in-2 item-out-2'
            >
              <Col
                xs={10}
                sm={11}
                md={11}
                lg={9}
                xl={7}
                className='content-section-text mx-auto px-0'
              >
                <p>
                  Start using retail analytics to measure lost potentials hidden in your store
                </p>
              </Col>
            </div>
            <div ref={refButton} className='item-in-3 item-out-3'>
              <Col>
                <Button onClick={handleScroll} className='content-section-btn'>
                  Get a Demo
                </Button>
              </Col>
            </div>
          </div>
        </Container>
        <img
          src={sectionImg}
          alt='...'
          className='img-fluid d-flex d-sm-none section-bg-img'
        />
        <div>
          <video
            id='videoBG'
            preload='metadata'
            ref={refVideo}
            poster={BGPoster}
            muted
            loop
            autoPlay
            playsInline
            className='w-100 d-none d-sm-flex'
            onPlay={startAnimation}
            onEnded={() => {
            }}
          >
            <source src={BGVideo} type='video/mp4' />
          </video>
        </div>
      </section>
    </>
  );
};

export default FirstSection;
