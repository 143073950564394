import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ImgPackage from 'images/logos-package';
import ImgPack01 from 'images/img-pack-01';
import ImgPack02 from 'images/img-pack-02';
import ImgPack03 from 'images/img-pack-03';




const PacksSection = () => {
  return (
    <section
      id="psg-packages" 
      className='section-packs'>
      <Container>
        <Row className='justify-content-center'>
          <Col xs={10} sm={12} md={8} lg={6}>
            <img className='img-package' src={ImgPackage} alt='Logos' />
          </Col>
        </Row>

      <Row className='justify-content-center'>
          <Col
            className='content-section-title'
            xs={12}
            xl={12}
            lg={12}
            uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
          >
            <h1>
              3 pre-approved PSG packages at <b>up to 80%</b> subsidy

            </h1>
          </Col>
        </Row>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 500'
        >
          <Col className='content-section-text' xs={12} md={8} lg={10}>
            <p>
              Providing pre-approved retail analytics solutions under the Productivity Solutions Grant (PSG) to physical / brick-and-mortar retailers in Singapore.

            </p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col
            xs={12} 
            sm={12}           
            md={4}
            lg={4}
            uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
            className='px-4'
          >
            
            <img className='img-1' src={ImgPack01} alt='Traffic2.0' />

            <div className='content-section-detail'>
            
            <h1 className='content-section-subtitle subtitle'>
              Traffic 2.0
            </h1>
            <p className=''>
              Automatically counts entrance traffic, measures shoppers passing by the store and captures the rate of those who entered. Lets retailers know the conversion rate of shoppers in store, so they can make changes when performance metrics are not up to standards.
            </p>
            </div>

          </Col>
          <Col
            xs={12} 
            sm={12}           
            md={4}
            lg={4}
            uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
            className='px-4'
          >
            <img className='img-2' src={ImgPack02} alt='Product engagement' />

            <div className='content-section-detail'>
            <h1 className='content-section-subtitle subtitle'>Product Engagement</h1>
            <p className=''>Measures shoppers in the store that have actually interacted with the product displays as well as the conversion rate of those products. Allows retailers to know if their visual merchandising strategies are working or not.</p>

            </div>
            

          </Col>
          <Col
            xs={12} 
            sm={12}           
            md={4}
            lg={4}
            uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
            className='px-4'
          >
            <img className='img-3' src={ImgPack03} alt='path to purchase' />

            <div className='content-section-detail'>
              <h1 className='content-section-subtitle subtitle'>Path-To-Purchase</h1>
              <p>Measures the whole path-to-purchase performance from the passby traffic, to shoppers that entered, all while knowing how many engaged with the products and bought something : Giving retailers a holistic view of shoppers' behaviour.</p>

            </div>

          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default PacksSection;
