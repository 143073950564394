import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import SuccessModal from './SuccessModal';
import { removeClass } from 'utilities/helpers';

const FourthSection = ({ refSection4, csrf }) => {
  const initialValues = {
    email: '',
    name: '',
    company: '',
    jobTitle: '',
    contactNumber: '',
    country: '',
    industry: '1 to 5 stores',
    openEnded: '',
  };
  const [hasError, setHasError] = useState({});
  const [errorMsg, setErrors] = useState({
    email: { type: 'Please enter a valid email address', currentError: '' },
    name: { type: 'Please enter your full name', currentError: '' },
    company: { type: 'Please enter your company name', currentError: '' },
    jobTitle: { type: ' Please enter your job title', currentError: '' },
    contactNumber: {
      type: 'Please enter your contact number',
      pattern: 'Please enter a valid number',
      currentError: '',
    },
    country: { type: 'Please enter your country', currentError: '' },
    industry: { type: 'Please select an option', currentError: '' },
    openEnded: { type: 'Please enter a valid text', currentError: '' },


    
  });
  const [values, setValues] = useState(initialValues);
  const [isValid, setIsValid] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const refForm = useRef(null);

  const validate = (input) => {
    if (
      input.validity.valueMissing ||
      input.validity.typeMismatch ||
      input.validity.patternMismatch
    ) {
      if (input.validity.valueMissing || input.validity.typeMismatch) {
        input.setCustomValidity(getError(input.name, 'type'));
        let newState = Object.assign({}, errorMsg);
        newState[input.name].currentError = errorMsg[input.name].type;
        setErrors(newState);
      } else {
        input.setCustomValidity(getError(input.name, 'pattern'));
        let newState = Object.assign({}, errorMsg);
        newState[input.name].currentError = errorMsg[input.name].pattern;
        setErrors(newState);
      }

      !input.className.includes('show-error')
        ? (input.className += ' show-error')
        : null;
    } else {
      input.className = input.className.replace('show-error', '');
      input.setCustomValidity('');
    }
  };

  const handleOnBlur = (event) => {
    event.preventDefault();
    let input = event.currentTarget;
    validate(input);
  };

  const handleOnChange = (event) => {
    event.preventDefault();
    let input = event.currentTarget;

    setValues({ ...values, [input.name]: input.value });
    validate(input);
  };

  const handleOnChangeChecks = (event) => {
    const target = event.target;
    setValues({ ...values, [target.name]: target.value });

    switch (target.name) {
    }
  };
  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    if (form.checkValidity()) {
      await fetch('/eligibility', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        body: JSON.stringify({ values }),
      }).then((res) => {
        if (res.ok) {
          setModalShow(true);
          setValues(initialValues);
        }
      });
    } else {
      const validation = Array.from(formData.keys()).reduce((acc, key) => {
        if (form.elements[key].validationMessage === '') {
          acc[key] = false;
        } else acc[key] = true;
        return acc;
      }, {});
      setHasError(validation);
    }
  };

  const getError = (field, errorType = 'type') => {
    errorType === 'type' ? errorMsg[field].type : errorMsg[field].pattern;
  };

  return (
    <section ref={refSection4} id='get-demo' className='section-4'>
      <Container>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
        >
          <Col className='content-section-title mb-4' xs={12} sm={12} md={10}>
            <h1>Fill up the form below to get a personalised demo of the RetailNext platform.</h1>
          </Col>
        </Row>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 500'
        >
          <Col className='content-section-text' xs={12} md={8} lg={10}>
            <p>Get an overview of the data insights you can expect and how to use them to improve your 
              store performance.
            </p>
          </Col>
        </Row>
        <Row className='justify-content-around eligibility-form'>
          <Container>
            <Form ref={refForm} noValidate onSubmit={handleOnSubmit}>
              <Col md={{ span: 10, offset: 1 }} lg={10} className='form-card'>
                <Row className='justify-content-center'>
                  <Col xs={12} sm={12} md={10} lg={8}>
                    <Form.Control
                      maxLength={100}
                      required
                      type='email'
                      value={values.email}
                      placeholder='Email'
                      name='email'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      className={hasError.email && 'show-error'}
                    />
                    <div className='error-message'>
                      {errorMsg.email.currentError}
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs={12} sm={12} md={10} lg={8}>
                    <Form.Control
                      maxLength={50}
                      required
                      type='text'
                      value={values.name}
                      placeholder='Full Name'
                      name='name'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      className={hasError.name && 'show-error'}
                    />
                    <div className='error-message'>
                      {errorMsg.name.currentError}
                    </div>
                  </Col>
                  
                </Row>
                <Row className='justify-content-center'>
                  <Col xs={12} sm={6} md={5} lg={4}>
                    <Form.Control
                      maxLength={50}
                      required
                      type='text'
                      value={values.company}
                      placeholder='Company Name'
                      name='company'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      className={hasError.company && 'show-error'}
                    />
                    <div className='error-message'>
                      {errorMsg.company.currentError}
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={5} lg={4}>
                    <Form.Control
                      maxLength={50}
                      required
                      type='text'
                      value={values.jobTitle}
                      placeholder='Job Title'
                      name='jobTitle'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      className={hasError.jobTitle && 'show-error'}
                    />
                    <div className='error-message'>
                      {errorMsg.jobTitle.currentError}
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs={12} sm={6} md={5} lg={4}>
                    <Form.Control
                      maxLength={50}
                      required
                      value={values.contactNumber}
                      type='text'
                      pattern='^(8|9)(\d){7}$'
                      placeholder='Contact Number'
                      name='contactNumber'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      className={hasError.contactNumber && 'show-error'}
                    />
                    <div className='error-message'>
                      {errorMsg.contactNumber.currentError}
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={5} lg={4}>
                    <Form.Control
                      maxLength={50}
                      required
                      type='text'
                      value={values.country}
                      placeholder='Country'
                      name='country'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      className={hasError.country && 'show-error'}
                    />
                    <div className='error-message'>
                      {errorMsg.country.currentError}
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col
                    className='col-lg-8 col-md-10 col-12 form-subtitle'
                    xs={12}
                  >
                  Store Count
                  </Col>
                  <Col
                    className='justify-content-center op-premise'
                    lg={8}
                    md={10}
                    xs={12}
                  >
                    <Col
                      className='custom-control custom-radio custom-control-inline col-12 col-sm-5 col-md-4'
                      xs={4}
                      sm={4}
                      md={4}
                    >
                      <input
                        required
                        type='radio'
                        id='industry-1'
                        value='1 to 5 stores'
                        checked={values.industry === '1 to 5 stores' ? true : false}
                        name='industry'
                        className='custom-control-input'
                        onChange={handleOnChangeChecks}
                      />
                      <label className='custom-control-label' htmlFor='industry-1'>
                        1 to 5 stores
                      </label>
                    </Col>
                    
                    

                    <Col
                      className='custom-control custom-radio custom-control-inline col-12 col-sm-5 col-md-4'
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <input
                        type='radio'
                        id='industry-2'
                        value='5 to 10 stores'
                        checked={values.industry === '5 to 10 stores' ? true : false}
                        name='industry'
                        className='custom-control-input'
                        onChange={handleOnChangeChecks}
                      />
                      <label className='custom-control-label' htmlFor='industry-2'>
                        5 to 10 stores
                      </label>
                    </Col>

                    <Col
                      className='custom-control custom-radio custom-control-inline col-12 col-sm-5 col-md-4'
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <input
                        type='radio'
                        id='industry-3'
                        value='over 10 stores'
                        checked={values.industry === 'over 10 stores' ? true : false}
                        name='industry'
                        className='custom-control-input'
                        onChange={handleOnChangeChecks}
                      />
                      <label className='custom-control-label' htmlFor='industry-3'>
                        over 10 stores
                      </label>
                    </Col>

                    <Col
                      className='custom-control custom-radio custom-control-inline col-12 col-sm-5 col-md-4'
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <input
                        type='radio'
                        id='industry-4'
                        value='0 - E-Comm only'
                        checked={values.industry === '0 - E-Comm only' ? true : false}
                        name='industry'
                        className='custom-control-input'
                        onChange={handleOnChangeChecks}
                      />
                      <label className='custom-control-label' htmlFor='industry-4'>
                        0 - E-Comm only
                      </label>
                    </Col>

                    <Col
                      className='custom-control custom-radio custom-control-inline col-12 col-sm-5 col-md-4'
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <input
                        type='radio'
                        id='industry-5'
                        value='Non-retailer'
                        checked={values.industry === 'Non-retailer' ? true : false}
                        name='industry'
                        className='custom-control-input'
                        onChange={handleOnChangeChecks}
                      />
                      <label className='custom-control-label' htmlFor='industry-5'>
                        Non-retailer
                      </label>
                    </Col>

                    <Col
                      className='custom-control custom-radio custom-control-inline col-12 col-sm-5 col-md-4'
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <input
                        type='radio'
                        id='industry-6'
                        value='Re-sell Partner'
                        checked={values.industry === 'Re-sell Partner' ? true : false}
                        name='industry'
                        className='custom-control-input'
                        onChange={handleOnChangeChecks}
                      />
                      <label className='custom-control-label' htmlFor='industry-6'>
                        Re-sell Partner
                      </label>
                    </Col>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                <Col
                    className='col-lg-8 col-md-10 col-12 form-subtitle'
                    xs={12}
                  >
                Tell us more about your business
                    
                  </Col>
                  <Col xs={12} sm={12} md={10} lg={8}>
                    <Form.Control
                      id='open-ended'
                      as='textarea'
                      rows={3}
                      value={values.openEnded}
                      name='openEnded'
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                    />
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col className='col-lg-8 col-md-10 col-12 form-subtitle col-12'>
                    
                    <p className='pdpa-policy'>By providing us with your business contact information, you agree to receive the latest updates on retail and technology as well as new services relating to DDS from us. Any e-mail sent by DDS provides you with the option to unsubscribe from the DDS mailing list.</p>
                  </Col>
                </Row>
                <Row className='justify-content-center mt-3'>
                  <Button
                    variant='primary'
                    type='submit'
                    className='content-section-btn'
                  >
                    Request for Demo
                  </Button>
                </Row>
              </Col>
            </Form>
          </Container>
        </Row>
      </Container>
      <SuccessModal
        dialogClassName='success-modal'
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </section>
  );
};

export default FourthSection;
