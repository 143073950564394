import React from 'react';

import IcoHeart from 'images/ico-heart';
import IcoGlobe from 'images/ico-globe';
import IcoSites from 'images/ico-sites';
import IcoFace from 'images/ico-face';
import IcoCake from 'images/ico-cake';

const Slider = () => {
  return (
    <div uk-slider='center: true'>
      <div
        className='uk-position-relative uk-visible-toggle uk-light'
        tabIndex='-1'
      >
        <ul className='uk-slider-items uk-grid'>
          <li className='uk-width-1-2'>
            <div className='uk-panel'>
              <div className='text-center'>
                <img src={IcoHeart} alt='heart' />
                <h6 className='content-section-highlight'> 450+</h6>
                <span className='content-highlight-detail'>
                  Customers globally
                </span>
              </div>
            </div>
          </li>
          <li className='uk-width-1-2'>
            <div className='uk-panel'>
              <div className='text-center'>
                <img src={IcoGlobe} alt='globe' />
                <h6 className='content-section-highlight'> 85+</h6>
                <span className='content-highlight-detail'>Countries</span>
              </div>
            </div>
          </li>
          <li className='uk-width-1-2'>
            <div className='uk-panel'>
              <div className='text-center'>
                <img src={IcoSites} alt='sites' />
                <h6 className='content-section-highlight'> 800</h6>
                <span className='content-highlight-detail'>
                  New sites per month
                </span>
              </div>
            </div>
          </li>
          <li className='uk-width-1-2'>
            <div className='uk-panel'>
              <div className='text-center'>
                <img src={IcoFace} alt='face' />
                <h6 className='content-section-highlight'> 200%</h6>
                <span className='content-highlight-detail'>YOY</span>
              </div>
            </div>
          </li>
          <li className='uk-width-1-2'>
            <div className='uk-panel'>
              <div className='text-center'>
                <img src={IcoCake} alt='cake' />
                <h6 className='content-section-highlight'> 2007</h6>
                <span className='content-highlight-detail'>Year founded</span>
              </div>
            </div>
          </li>
        </ul>

        <a
          className='uk-position-center-left uk-position-small uk-hidden-hover'
          href='#'
          uk-slidenav-previous='true'
          uk-slider-item='previous'
        ></a>
        <a
          className='uk-position-center-right uk-position-small uk-hidden-hover'
          href='#'
          uk-slidenav-next='true'
          uk-slider-item='next'
        ></a>
      </div>
      <ul className='uk-slider-nav uk-dotnav uk-flex-center uk-margin-medium-top'></ul>
    </div>
  );
};

export default Slider;
