import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from './Slider';

import IcoHeart from 'images/ico-heart';
import IcoGlobe from 'images/ico-globe';
import IcoSites from 'images/ico-sites';
import IcoFace from 'images/ico-face';
import IcoCake from 'images/ico-cake';
import retailn_logo from 'images/retailnext-logo';

const FifthSection = () => {
  return (
    <section className='section-5'>
      <Container>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
        >
          <Col className='col-auto'>
            <img className='' src={retailn_logo} />
          </Col>
        </Row>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 350'
        >
          <Col className='content-section-title mb-4' xs={20} md={10}>
            <h1>A glance at Aurora creators</h1>
          </Col>
        </Row>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 500'
        >
          <Col className='content-section-text' xs={12} md={10}>
            <p>
              The first retail vertical IoT integrated platform to bring
              e-commerce style shopper analytics to brick-and-mortar retailers,
              RetailNext is a pioneer in focusing entirely on optimizing the
              shopper experience. More than 450 retailers in over 85 countries
              have adopted RetailNext's analytics software and retail expertise
              to increase sales, reduce theft and eliminate unnecessary costs.
            </p>
          </Col>
        </Row>
        <Row
          className='justify-content-center d-none d-md-flex'
          xs={12}
          md={10}
          uk-scrollspy='target: > div; cls:uk-animation-slide-bottom-small; delay: 300'
        >
          <Col className='text-center mb-4' xs={4} md={2}>
            <img className='mb-3' src={IcoHeart} alt='heart' />
            <h6 className='content-section-highlight'> 450+</h6>
            <span className='content-highlight-detail'>Customers globally</span>
          </Col>
          <Col className='text-center mb-4' xs={4} md={2}>
            <img className='mb-3' src={IcoGlobe} alt='globe' />

            <h6 className='content-section-highlight'> 85+</h6>
            <span className='content-highlight-detail'>Countries</span>
          </Col>
          <Col className='text-center mb-4' xs={4} md={2}>
            <img className='mb-3' src={IcoSites} alt='sites' />

            <h6 className='content-section-highlight'>800</h6>
            <span className='content-highlight-detail'>
              New sites per month
            </span>
          </Col>
          <Col className='text-center mb-4' xs={4} md={2}>
            <img className='mb-3' src={IcoFace} alt='face' />

            <h6 className='content-section-highlight'> 200%</h6>
            <span className='content-highlight-detail'>YOY growth</span>
          </Col>
          <Col className='text-center mb-4' xs={4} md={2}>
            <img className='mb-3' src={IcoCake} alt='cake' />

            <h6 className='content-section-highlight'> 2007</h6>
            <span className='content-highlight-detail'>Year founded</span>
          </Col>
        </Row>
        <Row className='d-flex d-md-none'>
          <Slider />
        </Row>
      </Container>
    </section>
  );
};

export default FifthSection;
