import React, { useRef, useState } from 'react';

import { removeClass } from 'utilities/helpers';
import FirstSection from './FirstSection';
import PacksSection from './PacksSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';

const scrollToRef = (ref) =>
  window.scrollTo({ top: ref.current.offsetTop, left: 0, behavior: 'smooth' });

const Content = () => {
  const [brochure, setBrochure] = useState('');

  const [brochureSubmitted, setBrochureSubmitted] = useState(false);
  const refBrochureInput = useRef(null);
  const refSection4 = useRef(null);

  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute('content');

  const handleChangeBrochure = (event) => {
    event.preventDefault();
    removeClass('show-error', refBrochureInput);
    setBrochure(event.target.value);
  };
  const submitBrochure = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      refBrochureInput.current.className = `${refBrochureInput.current.className} show-error`;
      return;
    }
    const response = await fetch('/brochure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ email: brochure }),
    }).then((res) => {
      if (res.ok) {
        setBrochure('');
        setBrochureSubmitted(true);
      }
    });
  };

  return (
    <>
      <FirstSection handleScroll={() => scrollToRef(refSection4)} />
      <PacksSection />
      <SecondSection />
      <ThirdSection
        submitted={brochureSubmitted}
        refBrochureInput={refBrochureInput}
        brochure={brochure}
        onChangeBrochure={handleChangeBrochure}
        onSubmitBrochure={submitBrochure}
        onRemoveClass={removeClass}
      />
      <FourthSection refSection4={refSection4} csrf={csrf} />
      <FifthSection />
    </>
  );
};
export default Content;
