import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import modalIMG from 'images/thank-you-icon';

const SuccessModal = (props) => {
  return (
    <Modal {...props} size='lg' aria-labelledby='success-modal' centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='mb-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col className='col-auto mb-3 px-0 px-sm-1 px-md-4 px-lg-5'>
              <img src={modalIMG} alt='...' className='modal-img' />
            </Col>

            <Col xs={10} className='my-3'>
              <h1>Thank you for your submission</h1>
            </Col>
            <Col xs={12} md={10}>
              <p>
                You will be contacted shortly through email to schedule a demo with us and further information will be provided.
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
