import React, { useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SliderAurora from './SliderAurora';

const ThirdSection = ({
  submitted,
  refBrochureInput,
  brochure,
  onSubmitBrochure,
  onChangeBrochure,
}) => {
  return (
    <section
      id="how-aurora-sensor-works" 
      className='section-3'>
      <Container>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
        >
          <Col className='content-section-title  mb-lg-4'>
            <h1>How it Works - The Aurora Sensor</h1>
          </Col>
        </Row>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 500'
        >
          <Col className='content-section-text' xs={12} md={8} lg={10}>
            <p>Aurora is the all-in-one IoT sensor simpifying shopper behavior measurement and taking your retail business to the next level.

            </p>
            <p>
              From understanding precise shopper paths to their individual actions and behaviors. 
              Aurora goes beyond traditional traffic counting and conversion allowing you to better optimize your shopping experience for retail's revolution.
            </p>
          </Col>
        </Row>
        <Row className='justify-content-center mb-5'>
          <Col>
            <SliderAurora />
          </Col>
        </Row>
        <Row
          className='justify-content-center'
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 250'
        >
          <Col className='content-section-title'>
            <h2>Need more information?</h2>
          </Col>
        </Row>

        {!submitted ? (
          <form noValidate onSubmit={onSubmitBrochure}>
            <Row
              className='justify-content-center'
              uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 500'
            >
              <Col xs={12} sm={10} md={10} lg={8} xl={7}>
                <div className='input-group my-3'>
                  <input
                    ref={refBrochureInput}
                    id='brochure_email'
                    name='email'
                    value={brochure}
                    type='email'
                    className='form-control rounded mx-sm-0 mb-3 mb-sm-0'
                    placeholder='Your Email'
                    aria-label='Your Email'
                    aria-describedby='basic-addon2'
                    onChange={onChangeBrochure}
                  />
                  <div className='error-message'>
                    Please enter a valid email address
                  </div>
                  <div className='input-group-append mx-sm-0'>
                    <Button
                      className='content-section-btn brochure-btn'
                      type='submit'
                      disabled={brochure === ''}
                    >
                      More on Aurora
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='justify-content-center'>
              <Col className='my-3' xs={12} md={10} lg={8}>
                <p className='pdpa-policy'>By providing us with your business contact information, you agree to receive the latest updates on retail and technology as well as new services relating to DDS from us. Any e-mail sent by DDS provides you with the option to unsubscribe from the DDS mailing list.</p>
              </Col>
            </Row>
          </form>
        ) : (
          <Row className='justify-content-center'>
            <Col className='content-section-title my-4' xs='auto'>
              <p>Your brochure was sent to your e-mail</p>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default ThirdSection;
