module.exports = Object.freeze({
  DESCRIPTIONS: [
    {
      title: "1. Capture shoppers' in-store behaviour", //'Monitor live occupancy metrics',
      text:
       '',
        // 'Gain insights into store density metrics such as occupancy thresholds and limits across all stores, in real-time. Utilize data to forecast traffic trends and staffing levels. Access information on the go using the RetailNext user interface or mobile application.',
      video: 'icono01',
      detail: 'detail-border-1',
    },
    {
      title: "2. Captured data is sent to the RetailNext platform",//'Ensure compliance and communicate findings',
      text:
        '',
        // 'Use reports to identify high-traffic areas and notify teams when store limits are reached. Communicate data with shoppers by providing visibility into occupancy metrics prior to visits.',
      video: 'icono02',
      detail: 'detail-border-2',
    },
    {
      title: "3. Integrate data with other technologies",//'Plug and Play installation',
      text:
       '',
        // 'Installing an Aurora is as simple as mounting it and plugging it into your PoE switch or via a power injector. Aurora connects directly to the RetailNext Cloud. Subscription to RetailNext Cloud is required separately to activate the Aurora hardware for analytics.',
      video: 'icono03',
      detail: 'detail-border-4',
    },
    {
      title: "Start improving your retail performance",//'Integrate data with other technologies',
      text:
       '',
        // 'Easily connect the RetailNext API to end user applications such as virtual queuing apps, customer-facing digital signage or labor and task management tools.',
      video: 'icono04',
      detail: 'detail-border-3',
    },
  ],
});
