import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import IconPoster from 'images/icon_poster.jpg';

const Description = ({ data, idx }) => {
  const align = idx % 2 === 0 ? 'last' : 'first';
  const videoIcons = useRef([]);

  useEffect(() => {
    const handlePlay = (event) => {
      if(videoIcons.current[idx].currentTime === 0){
        if(idx !== 3) {
            videoIcons.current[idx].play();
        }
        else{
            const windowHeight = window.innerHeight;
            const offset = windowHeight * 0.5;

            if ((videoIcons.current[idx].getBoundingClientRect().top - offset  <= 0)) {
                videoIcons.current[idx].play();
            }
        }
      }
    };
    const handlePlayV2 = (event) => {
      const windowHeight = window.innerHeight;
      const offset = windowHeight * 0.5;
        if (videoIcons.current[idx].currentTime === 0){
                videoIcons.current[idx].play();
        }
    }  
      
    document.addEventListener('touchstart', handlePlayV2);
    document.addEventListener('scroll', handlePlay);
    
    return () => {
      document.removeEventListener('touchstart', handlePlayV2);
      document.removeEventListener('scroll', handlePlay);
    };
  });

  return (
    <>
      <Row className='content-section-description justify-content-md-center'>
        <Col
        className={`${idx === 3 ? 'p-0' : ''}`}
          xs={12}
          md={6}
          lg={6}
          xl={6}
          uk-scrollspy='cls:uk-animation-slide-bottom-small; delay: 500'
        >
          <video
            ref={(el) => (videoIcons.current[idx] = el)}
            loop={(idx!==3) ? true : undefined}
            muted
            autoPlay = {(idx!==3) ? true : undefined}
            playsInline
            src={require(`../packs/videos/${data.video}.mp4`)}
            className={`${idx === 3 ? `pr-0 scale-1-2 w-100 ${data.video}` : `w-100 ${data.video}`}`}

            type='video/mp4'
          ></video>
        </Col>
        <Col
          xs={{ span: 12, order: 'last' }}
          md={{ span: 6, order: align }}
          lg={{ span: 6, order: align }}

          uk-scrollspy='cls:uk-animation-fade; delay: 500'
        >
          <div className={data.detail} />
          <div className='content-section-subtitle'>{data.title}</div>
          <p className='description-text'>{data.text}</p>
        </Col>
      </Row>
    </>
  );
};

export default Description;
