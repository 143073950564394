import React from 'react';
import ReactDOM from 'react-dom';
import Index from 'components/Index';
import 'styles/app.css';
import 'styles/animation.css';
import 'styles/content.css';
import 'styles/footer.css';
import 'styles/slider.css';
import 'styles/successModal.css';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Index />,
    document.body.appendChild(document.createElement('div'))
  );
});
