export const removeClass = (cl, refName) => {
  refName.current.className = refName.current.className
    .split(' ')
    .filter((clas) => {
      if (clas !== cl) {
        return clas;
      }
    })
    .join(' ');
};
